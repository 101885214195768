import request from "@/utils/request";

export function basic_competencies_list(data) {
  return request({
    method: "post",
    url: "competence/list-kd",
    data: data
  });
}

export function post_class_list(data) {
  return request({
    method: "post",
    url: "class/add_class_detail",
    data: data
  });
}

export function save_competencies(data, type) {
  let url = "competence/add-kd";
  if (type == "edit") url = "competence/update-kd";
  return request({
    method: "post",
    url: url,
    data: data
  });
}

export function delete_competencies(data) {
  return request({
    method: "post",
    url: "competence/delete-kd",
    data: data
  });
}

export function get_ki(data) {
  return request({
    method: "post",
    url: "competence/detail-ki",
    data: data
  });
}

export function getListCompetenciesByLesson(data) {
  return request({
    url: "competence/list_basic_by_lesson",
    method: "POST",
    data: data
  });
}

export function listCompetenciesByLessonDetail(data) {
  return request({
    url: "competence/list_by_lesson_detail",
    method: "POST",
    data: data
  });
}
