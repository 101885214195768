<template>
  <div class="pa-4" :class="!darkmode && 'grey lighten-5'">
    <v-btn text class="mb-4" @click="goBack()"
      ><v-icon class="pr-2">mdi-arrow-left</v-icon>
      {{ $t("routes.score") }}</v-btn
    >
    <v-card class="rounded-lg pa-6" flat width="100%">
      <v-row v-if="$route.query.user">
        <v-col
          class="divider px-3"
          :class="$vuetify.breakpoint.xsOnly && 'mb-3'"
        >
          <div class="font-weight-light">{{ $t("app.name") }}</div>
          <div class="font-weight-bold">{{ student.name }}</div>
        </v-col>
        <v-col
          class="divider px-3"
          :class="$vuetify.breakpoint.xsOnly && 'mb-3'"
        >
          <div class="font-weight-light">
            {{ $t("routes.registration_number") }}
          </div>
          <div class="font-weight-bold">{{ student.nis }}</div>
        </v-col>
        <v-col class="divider px-3">
          <div class="font-weight-light">{{ $t("app.class") }}</div>
          <div class="font-weight-bold">{{ student.class_name }}</div>
        </v-col>
        <v-col class="divider px-3">
          <div class="font-weight-light">Semester</div>
          <div class="font-weight-bold">{{ student.semester }}</div>
        </v-col>
        <v-col class="divider px-3">
          <div class="font-weight-light">{{ $t("app.school_year") }}</div>
          <div v-if="student.start_year" class="font-weight-bold">
            {{ student.start_year }} /
            {{ student.end_year }}
          </div>
        </v-col>
      </v-row>
      <v-row v-else no-gutters justify="start">
        <v-col
          cols="6"
          sm="6"
          md="3"
          class="divider px-3"
          :class="$vuetify.breakpoint.xsOnly && 'mb-3'"
        >
          <div class="font-weight-light">{{ $t("app.subject") }}</div>
          <div class="font-weight-bold">{{ subject.lesson_name }}</div>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="3"
          class="divider px-3"
          :class="$vuetify.breakpoint.xsOnly && 'mb-3'"
        >
          <div class="font-weight-light">{{ $t("app.grade") }}</div>
          <div class="font-weight-bold">{{ subject.grade_name }}</div>
        </v-col>
        <v-col cols="6" sm="6" md="3" class="divider px-3">
          <div class="font-weight-light">{{ $t("app.class") }}</div>
          <div class="font-weight-bold">{{ subject.class_name }}</div>
        </v-col>
        <v-col cols="6" sm="6" md="3" class="px-3">
          <div class="font-weight-light">{{ $t("app.teacher") }}</div>
          <div class="font-weight-bold" v-if="subject.teachers">
            <span v-for="t in subject.teachers" :key="t.id">
              {{ t.name }}<br />
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- <v-row no-gutters class="my-4">
      {{ $t("score.description") }}
    </v-row> -->

    <v-card class="rounded-lg pa-6 mt-6" flat width="100%">
      <v-tabs v-model="menuTab" @change="getData()">
        <v-tabs-slider color="primary" />
        <v-tab>{{ $t("app.exam") }}</v-tab>
        <v-tab v-if="masterCompetenciesCore.length > 0">
          {{ $t("elearning.table.kd") }}
        </v-tab>
      </v-tabs>

      <v-radio-group v-if="menuTab" v-model="coreCompetencies" mandatory row>
        <v-radio
          v-for="c in masterCompetenciesCore"
          :key="c.id"
          :label="c.competencies"
          :value="c"
          hide-details
        />
      </v-radio-group>

      <v-row class="my-4">
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-autocomplete
            v-model="filter.type_1"
            :items="list.type_1"
            :label="$t('app.daily_tests')"
            item-value="id"
            item-text="title"
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.title }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ filter.type_1.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-autocomplete
            v-model="filter.type_2"
            :items="list.type_2"
            :label="$t('app.homework')"
            item-value="id"
            item-text="title"
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.title }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ filter.type_2.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-autocomplete
            v-model="filter.type_3"
            :items="list.type_3"
            :label="$t('app.midterm_exam')"
            item-value="id"
            item-text="title"
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.title }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ filter.type_3.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-autocomplete
            v-model="filter.type_4"
            :items="list.type_4"
            :label="$t('app.final_exams')"
            item-value="id"
            item-text="title"
            multiple
            outlined
            dense
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.title }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ filter.type_4.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <!-- checkPrivilages('ASC001') -->
        <v-col v-if="!menuTab && roleType !== 'STUDENT'" cols="12">
          <v-row
            :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
            no-gutters
            justify="end"
          >
            <v-btn
              :class="$vuetify.breakpoint.xsOnly && 'mb-6'"
              class="gradient-primary mr-2"
              dark
              depressed
              @click="isDialog = true"
            >
              {{ $t("score.input_exam") }}
              <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
            </v-btn>
            <v-btn
              :class="$vuetify.breakpoint.xsOnly && 'mb-6'"
              :disabled="!enableDownload"
              color="green"
              outlined
              depressed
            >
              <download-excel
                :fields="fieldsExcel"
                :data="body"
                :name="fileNameExcel()"
              >
                {{ $t("app.download") }}
                <v-icon class="ml-2" color="green">mdi-file-excel</v-icon>
              </download-excel>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="pt-4" />

      <v-data-table
        :headers="menuTab ? headerCore : header"
        :items="menuTab ? bodyCore : body"
        :loading="loading"
        :items-per-page="menuTab ? limitCore : limit"
        :options.sync="options"
        disable-pagination
        hide-default-footer
        hide-default-header
        class="elevation-0"
      >
        <template v-slot:header="{ props: { headers } }">
          <!-- // Tab Header Ulangan -->
          <thead v-if="menuTab == 0">
            <tr v-if="headers.length == 0">
              <th :colspan="headers.length">
                {{ $t("score.description") }}
              </th>
            </tr>
            <tr v-else>
              <th v-for="(h, i) in headers" :key="`${i}-${h.text}`">
                <v-row no-gutters>
                  <span class="text-truncate" style="max-width: 120px">
                    {{ h.text }}
                  </span>

                  <v-spacer v-if="headerCond(h)" />
                  <v-btn
                    v-if="headerCond(h)"
                    class="mr-1"
                    icon
                    x-small
                    @click="openDetailKd(h)"
                  >
                    <v-icon>mdi-exclamation</v-icon>
                  </v-btn>

                  <!-- v-if="headerCond(h) && checkPrivilages('ASC001')" -->
                  <v-tooltip
                    v-if="headerCond(h) && roleType !== 'STUDENT'"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="setDelete(h.value)"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("score.remove") }}</span>
                  </v-tooltip>
                </v-row>
              </th>
            </tr>
          </thead>

          <!-- // Tab Header KD -->
          <thead v-if="menuTab == 1">
            <tr v-if="headers.length == 0">
              <th :colspan="headers.length">
                {{ $t("score.description") }}
              </th>
            </tr>
            <template v-else>
              <tr>
                <th
                  v-for="(h, i) in headers"
                  :key="`${i}-${h.text}`"
                  :class="h.sub ? 'text-center' : ''"
                  :rowspan="h.sub ? 1 : 2"
                  :colspan="h.sub ? h.sub.length : 1"
                  style="min-width: 150px; max-width: 150px;"
                >
                  {{ h.text }}
                </th>
              </tr>
              <tr>
                <template v-for="h in headers">
                  <template v-if="h.sub">
                    <th
                      v-for="s in h.sub"
                      :key="s.id"
                      style="min-width: 150px; max-width: 150px;"
                      class="text-truncate"
                    >
                      {{ s.title }}
                    </th>
                  </template>
                </template>
              </tr>
            </template>
          </thead>
        </template>

        <template v-slot:body="{ items }">
          <tbody v-if="menuTab == 0">
            <tr v-for="(item, idx) in items" :key="item.id" :id="`slot${idx}`">
              <td
                v-for="(itemH, idxH) in header"
                :key="`${idxH}-${item[itemH.value].id}`"
              >
                <div v-if="$route.query.user">
                  <span>{{ item[itemH.value].value }}</span>
                </div>
                <div v-else>
                  <span v-if="headerCond(itemH, 'or')">
                    {{ item[itemH.value].value }}
                  </span>
                  <v-text-field
                    v-else
                    :id="`input${idx}${idxH}`"
                    v-model="item[itemH.value].value"
                    outlined
                    dense
                    maxlength="6"
                    hide-details
                    style="min-width: 110px"
                    @click="setScoreTemp(item[itemH.value], idx, idxH)"
                    @keypress="isNumberFloat($event)"
                    @blur="editScore(item[itemH.value], idx, idxH)"
                  >
                    <template v-slot:append>
                      <v-progress-circular
                        v-if="
                          loading && indexRow === idx && indexColumn === idxH
                        "
                        size="22"
                        color="info"
                        indeterminate
                      />
                      <v-icon
                        v-if="
                          statusUpdate === 1 &&
                            indexRow === idx &&
                            indexColumn === idxH
                        "
                        size="22"
                        color="success"
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-tooltip
                        right
                        v-if="
                          statusUpdate === 2 &&
                            indexRow === idx &&
                            indexColumn === idxH
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            size="22"
                            color="red"
                            dark
                          >
                            mdi-close-circle
                          </v-icon>
                        </template>
                        <span>{{ $t("score.error_update") }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="menuTab == 1">
            <tr v-for="(item, idx) in items" :key="item.id">
              <template v-for="(itemH, idxH) in headerCore">
                <template v-if="headerCond(itemH, 'or')">
                  <td :key="`${idxH}-${item[itemH.value].id}`">
                    <span>
                      {{ item[itemH.value].value }}
                    </span>
                  </td>
                </template>
                <template v-else>
                  <td
                    v-for="(itemS, idxS) in item[itemH.value].sub"
                    :key="`${idxS}-${item[itemH.value].id}-${itemS.id}`"
                  >
                    <div v-if="$route.query.user">
                      <span>{{ itemS.value }}</span>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="itemS.value"
                        :id="`inputCore${idx}${idxH}${idxS}`"
                        outlined
                        dense
                        maxlength="6"
                        hide-details
                        style="min-width: 110px"
                        @click="setScoreTemp(itemS, idx, idxH, idxS)"
                        @keypress="isNumberFloat($event)"
                        @blur="editScore(itemS)"
                      />
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <Pagination
        :length="menuTab ? lengthCore : length"
        :total="menuTab ? totalCore : total"
        :current-page="menuTab ? pageCore : page"
        :limit="menuTab ? limitCore : limit"
        :handler="paginateChange"
      />
    </v-card>

    <AddManual
      :isDialog="isDialog"
      :list="{
        list: this.list,
        type: this.listType,
        coreCompetencies: this.masterCompetencies
      }"
      :close="
        param => {
          if (param) this.getFilter();
          this.isDialog = false;
        }
      "
    />

    <v-dialog v-model="modalConfirm" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline" />
        <v-card-text class="text-center">
          <h1 class="my-4">{{ $t("app.warning") }}</h1>
          {{ $t("score.confirm") }}
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-btn
              depressed
              outlined
              color="primary"
              class="mr-6"
              @click="
                () => {
                  modalConfirm = false;
                  deleteId = null;
                }
              "
            >
              {{ $t("app.back") }}
            </v-btn>
            <v-btn
              :loading="loading"
              color="gradient-primary"
              dark
              depressed
              @click="removeExam"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailKd" width="600" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t("routes.basic_competencies") }}
          <v-spacer></v-spacer>
          <v-btn
            small
            icon
            @click="
              () => {
                detailKD = [];
                dialogDetailKd = false;
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="20px">
                    No.
                  </th>
                  <th class="text-left">
                    {{ $t("basic_competencies.chapter") }}
                  </th>
                  <th class="text-left">
                    {{ $t("routes.basic_competencies") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in detailKD" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.bab }}</td>
                  <td>{{ item.title }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getDetailSubject,
  getDetailStudent,
  getFilterList,
  getListCoreCompetencies,
  getListExam,
  updateScore,
  deleteExam,
  getMasterCoreCompetencies,
  getListCoreCompetenciesStudent,
  getExamChapter
} from "@/api/admin/academic/score";

import { getListCompetenciesByLesson } from "@/api/admin/academic/basicCompetencies";
import { checkPrivilages } from "@/utils/privilages";

import { isNumberFloat } from "@/utils/validate";
import Pagination from "@/components/Pagination";
import AddManual from "./scoreAddManual";
import i18n from "@/i18n";
import store from "@/store";

export default {
  components: {
    Pagination,
    AddManual
  },
  data() {
    return {
      dialogDetailKd: false,
      detailKD: [],
      menuTab: 0,
      isDialog: false,
      indexRow: -1,
      indexColumn: -1,
      modalConfirm: false,
      loading: false,
      subject: {},
      student: {},
      statusUpdate: 0,
      list: {},
      coreCompetencies: {},
      options: {},
      masterCompetencies: [],
      masterCompetenciesCore: [],
      filter: {
        lesson: this.$route.params.lesson,
        classroom: this.$route.params.classroom,
        type_1: [],
        type_2: [],
        type_3: [],
        type_4: []
      },
      filter_competencies: {
        lesson: this.$route.params.lesson,
        classroom: this.$route.params.classroom,
        competencies_core: {
          id: null,
          ki_title: null
        }
      },
      header: [],
      body: [],
      limit: 10,
      total: 0,
      length: 0,
      sort: "",
      page: 1,
      headerCore: [],
      bodyCore: [],
      limitCore: 10,
      totalCore: 0,
      lengthCore: 0,
      sortCore: "",
      pageCore: 1,
      scoreTemp: {},
      listType: [
        {
          id: 1,
          name: i18n.t("app.daily_tests")
        },
        {
          id: 2,
          name: i18n.t("app.homework")
        },
        {
          id: 3,
          name: i18n.t("app.midterm_exam")
        },
        {
          id: 4,
          name: i18n.t("app.final_exams")
        }
      ]
    };
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    },
    roleType() {
      return store.getters.g_role_type;
    },
    enableDownload() {
      return Object.keys(this.filter)
        .filter(e => /type/.test(e))
        .some(e => this.filter[e].length > 0);
    },
    fieldsExcel() {
      const fields = {};
      this.header.forEach(v => {
        fields[v.text] = {
          field: v.value,
          callback: item => {
            return item.value;
          }
        };
      });
      return fields;
    }
  },
  watch: {
    coreCompetencies(n) {
      if (n) {
        if (this.menuTab) {
          this.filter_competencies.competencies_core.id = n.id;
          this.filter_competencies.competencies_core.ki_title = n.ki_title;
          this.getData();
        }
      }
    },
    filter: {
      handler() {
        this.getData();
      },
      deep: true
    },
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.loading = true;
          if (this.menuTab == 0) {
            this.limit = param.itemsPerPage;
            this.sort = [param.sortBy[0]];
            this.page = param.page;
          } else {
            this.limitCore = param.itemsPerPage;
            this.sortCore = [param.sortBy[0]];
            this.pageCore = param.page;
          }
          this.getData();
        }
      }
    }
  },
  async created() {
    const param = this.$route.params;
    if (this.$route.query.user) {
      await getDetailStudent({
        school_year: param.classroom,
        lesson: param.lesson
      }).then(res => {
        if (res.data.code) {
          const d = res.data.data;
          this.student = {
            name: d.student.name,
            nis: d.student.nis,
            classroom: d.classroom,
            class_name: d.class_name,
            semester: d.school_year.semester,
            start_year: d.school_year.start_year,
            end_year: d.school_year.end_year
          };
          this.filter_competencies.classroom = d.classroom;
          this.filter.classroom = d.classroom;
        }
      });
    } else {
      await getDetailSubject(param).then(res => {
        if (res.data.code) {
          this.subject = res.data.data;
        }
      });
    }

    await this.getFilter();
    await getListCompetenciesByLesson({
      lesson: param.lesson
    }).then(res => {
      if (res.data.code) {
        this.masterCompetencies = res.data.data;
      }
    });

    await getMasterCoreCompetencies({
      lesson: param.lesson
    }).then(res => {
      if (res.data.code) {
        this.masterCompetenciesCore = res.data.data;
      }
    });

    this.getData();
  },
  methods: {
    fileNameExcel() {
      const { grade_name, lesson_name, class_name } = this.subject;
      return `${i18n.t("app.score")} ${lesson_name} - ${i18n.t(
        "app.grade"
      )} ${grade_name} - ${i18n.t("app.class")} ${class_name}.xls`;
    },
    checkPrivilages: name => checkPrivilages(name),
    openDetailKd(h) {
      const key = h.value;
      getExamChapter({
        key: key
      }).then(res => {
        if (res.data.code) {
          this.detailKD = res.data.data;
          this.dialogDetailKd = true;
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: "error"
          });
        }
      });
    },
    headerCond(h, or) {
      if (or) {
        return h.value == "name" || h.value == "avg_uh" || h.value == "avg_pr";
      } else
        return h.value != "name" && h.value != "avg_uh" && h.value != "avg_pr";
    },
    setScoreTemp(item, idx, idxH, idxS) {
      let inputTag = "";

      this.scoreTemp = JSON.parse(JSON.stringify(item));
      if (this.menuTab) {
        inputTag = document.getElementById(`inputCore${idx}${idxH}${idxS}`);
      } else {
        inputTag = document.getElementById(`input${idx}${idxH}`);
      }

      inputTag.select();
    },
    goBack() {
      if (this.$route.query.user) {
        this.$router.push(
          `/academic/student-score?schoolyear=${this.$route.params.classroom}`
        );
      } else {
        this.$router.push({
          path: "/academic/score",
          query: { ...this.$route.query }
        });
      }
    },
    isNumberFloat: evt => isNumberFloat(evt),
    getFilter() {
      let data = this.$route.params;
      if (this.$route.query.user) {
        data = {
          lesson: this.$route.params.lesson,
          classroom: this.filter_competencies.classroom
        };
      }
      getFilterList(data).then(res => {
        if (res.data.code) {
          this.list = res.data.data;
        }
      });
    },
    paginateChange(page, limit) {
      if (this.menuTab == 0) {
        this.limit = limit;
        this.page = page;
      } else {
        this.limitCore = limit;
        this.pageCore = page;
      }
      this.getData();
    },
    getData() {
      this.loading = true;
      if (this.menuTab == 0) {
        this.header = [];
        this.total = 0;
        this.length = 0;
        this.body = [];

        getListExam({
          ...this.filter,
          limit: this.limit,
          page: this.page
        }).then(res => {
          if (res.data.code) {
            const d = res.data.data;
            const header = [];

            Promise.all(
              d.th.map(r => {
                // const condition =
                //   r.key == "name" || r.key == "avg_uh" || r.key == "avg_pr";
                header.push({
                  text: r.title,
                  align: "left",
                  value: r.key
                  // width: condition ? "" : 300
                });
              })
            );

            this.header = header;
            this.total = d.total;
            this.length = d.last_page;
            this.body = d.td;
          }
          this.loading = false;
        });
      } else {
        if (this.filter_competencies.competencies_core.id) {
          this.headerCore = [];
          this.totalCore = 0;
          this.lengthCore = 0;
          this.bodyCore = [];

          const queryParam = {
            ...this.filter_competencies,
            ...this.filter,
            limit: this.limitCore,
            page: this.pageCore
          };

          if (this.$route.query.user) {
            getListCoreCompetenciesStudent(queryParam).then(res => {
              if (res.data.code) {
                const d = res.data.data;
                const header = [];

                Promise.all(
                  d.th.map(r => {
                    header.push({
                      text: r.title,
                      align: "left",
                      value: r.key,
                      sub: r.sub ? r.sub : null
                    });
                  })
                );

                this.headerCore = header;
                this.totalCore = d.total;
                this.lengthCore = d.last_page;
                this.bodyCore = d.td;
              }
              this.loading = false;
            });
          } else {
            getListCoreCompetencies(queryParam).then(res => {
              if (res.data.code) {
                const d = res.data.data;
                const header = [];

                Promise.all(
                  d.th.map(r => {
                    header.push({
                      text: r.title,
                      align: "left",
                      value: r.key,
                      sub: r.sub ? r.sub : null
                    });
                  })
                );

                this.headerCore = header;
                this.totalCore = d.total;
                this.lengthCore = d.last_page;
                this.bodyCore = d.td;
              }
              this.loading = false;
            });
          }
        }
      }
    },
    editScore(data, idx, idxH) {
      if (data.value) {
        const condition = data.value != this.scoreTemp.value;
        if (condition) {
          this.loading = true;
          this.indexRow = idx;
          this.indexColumn = idxH;
          updateScore(data, this.menuTab)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_edit"),
                  color: "success"
                });
                this.statusUpdate = 1;
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
                this.loading = false;
                this.statusUpdate = 2;
              }
              this.setTimeResponse();
            })
            .catch(() => ((this.loading = false), (this.statusUpdate = 2)));
        }
      }
    },
    setTimeResponse() {
      if (this.statusUpdate === 1) {
        setTimeout(() => {
          this.statusUpdate = 0;
        }, 1500);
        this.loading = false;
      } else if (this.statusUpdate === 2) {
        this.statusUpdate = 2;
      }
    },
    setDelete(data) {
      this.modalConfirm = true;
      this.deleteId = data;
    },
    removeExam() {
      this.loading = true;
      deleteExam({ exam_id: this.deleteId })
        .then(async res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("app.success_delete"),
              color: "success"
            });
            this.modalConfirm = false;
            this.deleteId = null;
            await this.getFilter();
            await this.getData();
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    }
  }
};
</script>
