<template>
  <v-row>
    <v-dialog
      v-model="isDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      persistent
      width="600"
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ $t("score.input_exam") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="form" v-model="form" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-select
                  v-model="score.type"
                  :items="list.type"
                  item-value="id"
                  item-text="name"
                  :label="$t('score.form.type')"
                  :rules="[v => !!v || $t('app.required')]"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="score.kd"
                  :items="list.coreCompetencies"
                  item-value="id"
                  :item-text="e => e.core_competencies + ' - ' + e.kd_chapter"
                  :label="$t('score.form.core_competencies')"
                  :rules="[v => v.length > 0 || $t('app.required')]"
                  multiple
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="score.title"
                  :label="$t('score.form.title')"
                  :rules="[v => !!v || $t('app.required')]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-layout justify-end class="ma-5">
            <v-btn
              class="mr-2"
              outlined
              color="primary"
              @click="closeDialog()"
              >{{ $t("app.back") }}</v-btn
            >
            <v-btn
              :loading="loading"
              class="gradient-primary"
              dark
              depressed
              @click="submit"
              >{{ $t("app.save") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { saveManualScore } from "@/api/admin/academic/score";

export default {
  props: {
    isDialog: {
      type: Boolean,
      required: true
    },
    list: Object,
    close: Function
  },
  data() {
    return {
      form: false,
      loading: false,
      score: {
        lesson: Number(this.$route.params.lesson),
        classroom: Number(this.$route.params.classroom),
        title: "",
        type: null,
        kd: []
      }
    };
  },
  methods: {
    closeDialog(param) {
      this.$refs.form.reset();
      this.close(param);
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (this.score.type == 3 && this.list.list.type_3.length >= 1) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("score.midterm_exam_exist"),
            color: "warning"
          });
        } else if (this.score.type == 4 && this.list.list.type_4.length >= 1) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("score.final_exam_exist"),
            color: "warning"
          });
        } else {
          this.loading = true;
          saveManualScore(this.score)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.closeDialog("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loading = false;
            })
            .catch(() => (this.loading = false));
        }
      }
    }
  }
};
</script>
