import request from "@/utils/request";

export function getListSubject(data) {
  return request({
    url: "exam/list_class",
    method: "POST",
    data: data
  });
}

export function getDetailSubject(data) {
  return request({
    url: "exam/header",
    method: "POST",
    data: data
  });
}

export function getFilterList(data) {
  return request({
    url: "exam/master_type",
    method: "POST",
    data: data
  });
}

export function getListCoreCompetencies(data) {
  return request({
    url: "exam/list_competencies",
    method: "POST",
    data: data
  });
}

export function getListExam(data) {
  return request({
    url: "exam/list",
    method: "POST",
    data: data
  });
}

export function getMasterCoreCompetencies(data) {
  return request({
    url: "exam/competencies_core",
    method: "POST",
    data: data
  });
}

export function saveManualScore(data) {
  return request({
    url: "exam/add",
    method: "POST",
    data: data
  });
}

export function updateScore(data, type) {
  let url = "exam/update_score";
  if (type == 1) url = "exam/update_score_kd";

  return request({
    url: url,
    method: "POST",
    data: data
  });
}

export function deleteExam(data) {
  return request({
    url: "exam/remove",
    method: "POST",
    data: data
  });
}

// student

export function getExamStudent(data) {
  return request({
    url: "exam_student/list_class",
    method: "POST",
    data: data
  });
}

export function getDetailStudent(data) {
  return request({
    url: "exam_student/header",
    method: "POST",
    data: data
  });
}

export function getListCoreCompetenciesStudent(data) {
  return request({
    url: "exam_student/list_competencies",
    method: "POST",
    data: data
  });
}

export function getExamChapter(data) {
  return request({
    url: "/exam/bab",
    method: "POST",
    data: data
  });
}
